#technology {
  display: flex;
  flex-direction: column;
  margin-top: 650px;
  margin-left: 132px;
  position: relative;
}

#technology__title {
  font-weight: 700;
  font-size: 40px;
  margin: 0;
  color: #FFFFFF;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 102px;
    height: 15px;
    border-radius: 0 18.5px 18.5px 0;
    background-color: #2196F3;
    top: 15px;
    left: -132px;
  }
}

#technology__list {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding: 0;
}

.technology__list__block {
  font-weight: 400;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 0 0 0;
  list-style: none;
  cursor: pointer;
  width: 260px;
  height: 64px;
  transition: 0.1s;

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 64px;
    border-radius: 10px 0 0 10px;
    background-color: #B2EBF2;
    top: 0;
    left: -20px;
    opacity: 0;
  }
}

#technology__image {
  width: 100vw;
  height: 962px;
  position: absolute;
  left: -132px;
  top: -262px;
  z-index: -1;
  background-image: url(../assets/StarsTechnology.webp);
}

#technology__list__block-active {
  background: #B2EBF2;
  border-radius: 0 10px 10px 0;
  width: 260px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #171217;
  position: relative;
  margin-bottom: 40px;
  transition: 0.1s;

  &:before {
    transition: 0.1s;
    opacity: 1;
  }
}

#technology__stack-image {
  width: 1117.81px;
  height: 685.55px;
  position: absolute;
  left: 514px;
  top: -100px;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (min-width: 2000px) {
  #technology {
    width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  #technology__image {
    width: calc(100vw + 500px);
    height: 1362px;
    position: absolute;
    left: -500px;
    top: -572px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -3;
  }

  #technology__stack-image {
    width: 1117.81px;
    height: 685.55px;
    left: 514px;
    top: -100px;
  }

  #technology__title {
    &:before {
      border-radius: 18.5px;
    }
  }
}

@media screen and (min-width: 1990px) and (max-width: 2050px) {
  #technology__image {
    height: 1262px;
    top: -340px;
  }

  #technology__stack-image {
    width: 1117.81px;
    height: 685.55px;
    left: 514px;
    top: 0;
  }
}

@media screen and (max-width: 1800px) {
  #technology {
    margin-top: 417px;
    margin-left: 111px;
  }

  #technology__title {
    font-size: 22px;

    &:before {
      height: 11px;
      top: 7px;
    }
  }

  .technology__list__block {
    font-size: 20px;
    width: 203px;
    height: 64px;

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 64px;
      border-radius: 10px 0 0 10px;
      background-color: #B2EBF2;
      top: 0;
      left: -18px;
      opacity: 0;
    }
  }

  #technology__list__block-active {
    width: 221px;
    height: 64px;
    margin-bottom: 20px;
  }

  #technology__image {
    width: 100vw;
    height: 800px;
    left: -111px;
    top: -262px;
    z-index: -2;
    background-size: cover;
  }

  #technology__stack-image {
    width: 756px;
    height: 666px;
    background-size: 100%;
    background-repeat: no-repeat;
    left: 384px;
    top: -160px;
  }

}

@media screen and (min-width: 701px) and (max-width: 1200px) {
  #technology {
    margin-top: 505px;
    margin-left: 33px;
  }

  #technology__title {
    font-size: 20px;

    &:before {
      height: 11px;
      top: 6px;
      left: -112px;
    }
  }

  .technology__list__block {
    font-size: 18px;
    width: 221px;
    height: 64px;
  }

  #technology__list__block-active {
    width: 221px;
    height: 64px;
    margin-bottom: 20px;
  }

  #technology__image {
    width: 100vw;
    height: 800px;
    left: -35px;
    top: -262px;
  }

  #technology__stack-image {
    width: 473.23px;
    height: 382.35px;
    background-size: auto;
    background-repeat: no-repeat;
    left: 261px;
    top: 0;
  }

  #technology__container {
    display: flex;
    position: absolute;
    width: 65px;
    left: 490px;
    top: 400px;
    z-index: 3;
  }

  .technology__container__round {
    width: 7px;
    height: 7px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 100%;
    margin-right: 9px;
  }

  #technology__container__round-active {
    background-color: #FFFFFF;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) {
  #technology {
    margin-top: 810px;
    margin-left: 20px;
    position: relative;

    &:before {
      content: '';
      width: 98%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      position: absolute;
      left: 50%;
      top: 61px;
      margin-left: calc(-45% - 20px);
      z-index: 999;
    }

    &:after {
      content: '';
      width: 98%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      position: absolute;
      left: 50%;
      top: 121px;
      margin-left: calc(-45% - 20px);
    }
  }

  #technology__title {
    font-size: 20px;

    &:before {
      content: none;
    }

    &:after {
      content: '';
      position: absolute;
      width: 182px;
      height: 15px;
      border-radius: 18.5px 0 0 18.5px;
      background-color: #2196F3;
      top: 5px;
      right: 0;
      z-index: 1;
    }
  }

  #technology__list {
    flex-direction: row;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 100px;
  }

  .technology__list__block {
    font-weight: 400;
    font-size: 16px;
    margin: 0 40px 0 0;
    height: auto;
  }

  #technology__list__block-active {
    background: transparent;
    border-radius: 0;
    width: auto;
    height: auto;
    display: inline;
    color: #00BCD4;

    &:before {
      content: none;
    }
  }

  #technology__image {
    //display: none;
    width: 100vw;
    height: 720px;
    top: -70px;
    left: -20px;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.6;
    background-image: url(../assets/StarsMob.png);
  }

  #technology__stack-image {
    width: 264.76px;
    height: 280.91px;
    position: absolute;
    left: 50%;
    top: 180px;
    margin-left: -142px;
    background-repeat: no-repeat;
    //background-position: center;
    background-size: auto;
  }


  #technology__container {
    display: flex;
    position: absolute;
    width: 65px;
    left: 50%;
    margin-left: -32px;
    top: 470px;
  }

  .technology__container__round {
    width: 7px;
    height: 7px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 100%;
    margin-right: 9px;
  }

  #technology__container__round-active {
    background-color: #FFFFFF;
    z-index: -1;
  }
}

@media screen and (max-width: 360px) {
  #technology {
    margin-top: 710px;

    &:before {
      width: 98%;
    }

    &:after {
      content: '';
      width: 98%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      position: absolute;
      left: 50%;
      top: 110px;
      margin-left: calc(-45% - 20px);
    }
  }

  #technology__title {
    font-size: 15px;
    z-index: 1;

    &:before {
      content: none;
    }

    &:after {
      content: '';
      position: absolute;
      width: 170px;
      height: 15px;
      border-radius: 18.5px 0 0 18.5px;
      background-color: #2196F3;
      top: 1px;
      right: 0;
      z-index: 1;
    }
  }

  .technology__list__block {
    font-size: 14px;
  }
}