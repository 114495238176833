#team {
  margin-top: 30px;
  position: relative;
}

#team__title {
  font-weight: 700;
  font-size: 40px;
  margin: 0 0 0 137px;
  color: #FFFFFF;
}

#team__container {
  display: flex;
  margin-left: 820px;
}

.team__container__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 60px;
  position: relative;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:before {
    content: "";
    position: absolute;
    width: 284px;
    height: 284px;
    border: 6px solid #B2EBF2;
    border-radius: 22px;
    opacity: 0.3;
    top: -30px;
    z-index: -1;
  }

  &:first-child {
    &:before {
      left: -30px;
    }
  }

  &:last-child {
    &:before {
      right: -30px;
    }
  }
}

.team__container__block__image {
  width: 288px;
  height: 283px;
  border-radius: 22px;
}

.team__container__block__name {
  margin: 30px 0 0 0;
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
}

.team__container__block__post {
  margin: 16px 0 0 0;
  font-weight: 700;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.6);
}

.team__container__block__button {
  width: 176px;
  height: 57px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  background-color: transparent;
  outline: none;
  font-weight: 700;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Roboto', sans-serif;
  margin-top: 16px;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    background-color: #FFFFFF;
    color: #171217;
    border: none;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.6);
    color: #171217;
    border: none;
  }
}

#team__image {
  position: absolute;
  top: 110px;
  left: -128px;
  z-index: -1;
  width: 795px;
  height: 398px;
  background: url(../assets/Team.png) no-repeat;
}

@media screen and (min-width: 2000px) {
  #team {
    width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1800px) {
  #team__title {
    font-size: 22px;
    margin: 0 0 0 111px;
  }

  #team__container {
    margin-left: 418px;
    margin-top: 79px;
  }

  .team__container__block__image {
    width: 227.15px;
    height: 223.21px;
  }

  .team__container__block__name {
    font-size: 20px;
  }

  .team__container__block__post {
    font-size: 20px;
  }

  .team__container__block {
    margin-right: 40px;

    &:before {
      width: 224px;
      height: 224px;
      top: -20px;
    }

    &:first-child {
      &:before {
        left: -20px;
      }
    }

    &:last-child {
      &:before {
        right: -20px;
      }
    }
  }

  .team__container__block__button {
    font-size: 16px;
  }

  #team__image {
    top: 110px;
    left: -280px;
    width: 686px;
    height: 345px;
    background-size: cover;
    z-index: -2;
  }
}

@media screen and (min-width: 701px) and (max-width: 1200px) {
  #team__title {
    font-size: 20px;
    margin: 0 0 0 32px;
  }

  #team__container {
    margin-left: 226px;
    margin-top: 25px;
  }

  .team__container__block__image {
    width: 156px;
    height: 156px;
  }
  .team__container__block__name {
    font-size: 16px;
  }

  .team__container__block__post {
    font-size: 16px;
  }

  .team__container__block {
    margin-right: 20px;

    &:before {
      width: 156px;
      height: 156px;
      top: -20px;
    }

    &:first-child {
      &:before {
        left: -20px;
      }
    }

    &:last-child {
      &:before {
        right: -20px;
      }
    }
  }

  .team__container__block__button {
    font-size: 16px;
    width: 156px;
    height: 57px;
  }

  #team__image {
    top: 70px;
    left: -330px;
    width: 560px;
    height: 286px;
    background-size: 100%;
    z-index: -2;
  }
}

@media screen and (max-width: 700px) {
  #team {
    margin-top: 121px;
  }

  #team__title {
    font-size: 20px;
    margin: 0 0 0 20px;
  }

  #team__container {
    margin-left: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .team__container__block {
    position: relative;
    margin-top: 50px;
    margin-left: 20px;
    flex-direction: row;

    div {
      margin-left: 31px;
    }

    &:before {
      width: 161px;
      height: 163px;
      border: 3px solid #B2EBF2;
      top: -10px;
    }

    &:first-child {
      margin-top: 172px;

      &:before {
        left: 10px;
      }
    }

    &:last-child {
      &:before {
        left: -10px;
        right: 0;
      }
    }
  }

  .team__container__block__image {
    width: 167.93px;
    height: 165.01px;
  }

  .team__container__block__name {
    margin: 0;
    font-size: 20px;
  }

  .team__container__block__post {
    margin: 16px 0 0 0;
    font-size: 18px;
  }
  .team__container__block__button {
    width: 140px;
    height: 57px;
    font-size: 16px;
  }

  #team__image {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: url(../assets/TeamMob.png) no-repeat;
    top: 0;
    left: 110px;
    z-index: -1;
    width: 383px;
    height: 142px;
  }
}

@media screen and (max-width: 370px) {
  .team__container__block {
    margin-left: 10px;

    div {
      margin-left: 21px;
    }

    &:before {
      width: 121px;
      height: 123px;
      border: 3px solid #B2EBF2;
      top: -10px;
    }

    &:first-child {
      margin-top: 172px;

      &:before {
        left: 10px;
      }
    }

    &:last-child {
      &:before {
        left: -10px;
        right: 0;
      }
    }
  }

  .team__container__block__image {
    width: 127.93px;
    height: 125.01px;
  }

  .team__container__block__name {
    font-size: 18px;
  }

  .team__container__block__post {
    margin: 12px 0 0 0;
    font-size: 16px;
  }
  .team__container__block__button {
    width: 130px;
    height: 47px;
    font-size: 16px;
  }

}