#about {
  margin-top: 501px;
  margin-left: 132px;
}

#about__title {
  font-weight: 500;
  font-size: 40px;
  margin: 0;
  color: rgba(255, 255, 255, 0.87);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 102px;
    height: 15px;
    border-radius: 0 18.5px 18.5px 0;
    background-color: #2196F3;
    top: 15px;
    left: -132px;
  }
}

#about__description {
  font-weight: 400;
  font-size: 22px;
  color: rgba(255, 255, 255, 0.6);
  width: 540px;
  margin: 40px 0 0 0;
}

#about__image {
  width: 1209.81px;
  height: 899.37px;
  position: absolute;
  left: 605.38px;
  top: 1078.95px;
  z-index: -1;
  background-image: url(../assets/AboutPC.png);
}

@media screen and (min-width: 2100px) {
  #about {
    width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
  #about__image {
    right: 0;
    left: 1005.38px;
  }
  #about__title {
    &:before {
      border-radius: 18.5px;
    }
  }
}

@media screen and (min-width: 1990px) and (max-width: 2050px) {
  #about__image {
    right: 0;
    left: 805.38px;
    top: 1028.95px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1800px) {
  #about {
    margin-top: 403px;
    margin-left: 111px;
  }

  #about__title {
    font-size: 22px;

    &:before {
      height: 11px;
      top: 7px;
    }
  }

  #about__description {
    font-size: 18px;
    width: 428px;
    margin: 20px 0 0 0;
  }

  #about__image {
    width: 720px;
    height: 535.25px;
    left: 548px;
    top: 975.69px;
    z-index: -1;
    background-image: url(../assets/AboutPC.png);
    background-size: cover;
  }
}

@media screen and (max-width: 1200px) {
  #about {
    margin-top: 120px;
    margin-left: 20px;
  }

  #about__title {
    font-size: 20px;
    z-index: -1;

    &:before {
      content: none;
    }

    &:after {
      content: '';
      position: absolute;
      width: 249px;
      height: 15px;
      border-radius: 18.5px 0 0 18.5px;
      background-color: #2196F3;
      top: 5px;
      right: 0;
      z-index: -1;
    }
  }
  #about__description {
    width: 335px;
    font-size: 15px;
    margin-top: 16px;
  }
  #about__image {
    width: 360px;
    height: 700px;
    position: absolute;
    top: 937px;
    background-image: url(../assets/AboutMob.png);
    background-size: 100%;
    background-repeat: no-repeat;
    left: 50%;
    margin-left: -180px;
  }
}

@media screen and (min-width: 610px) and (max-width: 768px) {
  #about__image {
    top: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  #about {
    margin-top: 387px;
    margin-left: 33px;
  }

  #about__title {
    font-size: 20px;

    &:after {
      content: none;
    }

    &:before {
      content: '';
      position: absolute;
      width: 122px;
      height: 11px;
      border-radius: 0 18.5px 18.5px 0;
      background-color: #2196F3;
      top: 7px;
      left: -132px;
    }
  }

  #about__description {
    width: 312px;
    font-size: 16px;
    margin-top: 20px;
  }

  #about__image {
    width: 416.63px;
    height: 695px;
    top: 937px;
    left: 350px;
    margin-left: 0;
  }
}

@media screen and (max-width: 360px) {
  #about__title {
    font-size: 16px;
    z-index: -1;

    &:before {
      content: none;
    }

    &:after {
      content: '';
      position: absolute;
      width: 210px;
      height: 15px;
      border-radius: 18.5px 0 0 18.5px;
      background-color: #2196F3;
      top: 2px;
      right: 0;
      z-index: -1;
    }
  }

  #about__description {
    width: 300px;
    font-size: 13px;
    margin-top: 16px;
  }

  #about__image {
    width: 300px;
    height: 700px;
    position: absolute;
    top: 900px;
    background-image: url(../assets/AboutMob.png);
    background-size: 100%;
    background-repeat: no-repeat;
    left: 50%;
    margin-left: -150px;
  }
}