#main {
  display: flex;
  flex-direction: column;
  margin: 247px 0 0 130px;
}

#main__title {
  font-weight: 700;
  font-size: 120px;
  color: #FFFFFF;
  width: 820px;
  margin: 0;
}

#main__description {
  font-weight: 400;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.6);
  margin: 60px 0 0 0;
  width: 492px;
}

#main__button {
  width: 204px;
  height: 57px;
  border: 1px solid #B2EBF2;
  border-radius: 10px;
  margin-top: 50px;
  outline: none;
  color: #B2EBF2;
  font-weight: 500;
  font-size: 18px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  transition: 0.3s;

  path {
    transition: 0.3s;
  }

  &:hover {
    background-color: #00BCD4;
    color: #FFFFFF;
    border: none;

    path {
      fill: #fff;
    }
  }

  &:active {
    background-color: #B2EBF2;
    color: #FFFFFF;
    border: none;

    path {
      fill: #fff;
    }
  }

  svg {
    margin-left: 20px;
  }
}

#main__deco {
  position: absolute;
  width: 1905.67px;
  height: 1918.58px;
  left: 570px;
  top: -729.99px;
  z-index: -999;
  transform: rotate(7.41deg);
}

#main__planet {
  position: absolute;
  z-index: -998;
  width: 1102.2px;
  height: 1094.86px;
  left: 1040px;
  top: -108px;
}

@media screen and (min-width: 2000px) {
  #main {
    width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
  #main__planet {
    left: 1240px;
  }
  #main__deco {
    left: 770px;
  }
}

@media screen and (max-width: 1500px) {
  #main {
    display: flex;
    flex-direction: column;
    margin: 247px 0 0 107px;
  }

  #main__title {
    font-size: 80px;
    width: 547px;
  }

  #main__description {
    font-size: 18px;
    margin: 40px 0 0 0;
  }

  #main__button {
    width: 191.9px;
    height: 55px;
    margin-top: 30px;
    font-size: 16px;
  }

  #main__deco {
    position: absolute;
    width: 1588.86px;
    height: 1599.63px;
    left: 462.4px;
    top: -573.71px;
    z-index: -999;
    transform: rotate(7.41deg);
  }

  #main__planet {
    position: absolute;
    z-index: -998;
    width: 769px;
    height: 763.87px;
    left: 760px;
    top: 87.49px;

  }
}

@media screen and (max-width: 1200px) {
  #main__title {
    font-size: 38px;
  }

  #main {
    margin: 245px 0 0 20px;
  }

  #main__description {
    font-size: 15px;
    width: 309px;
    margin: 30px 0 0 0;
  }

  #main__button {
    width: 185.9px;
    height: 54.5px;
    border: 1px solid #B2EBF2;
    border-radius: 10px;
    font-size: 15px;

    svg {
      width: 15.9px;
      height: 18.5px;
    }
  }

  #main__deco {
    position: absolute;
    width: 573.93px;
    height: 577.81px;
    left: -40px;
    top: -187.95px;
    z-index: -999;
    transform: rotate(7.41deg);
  }


  #main__planet {
    width: 266px;
    height: 264.23px;
    left: 164px;
    top: 86px;
  }
}

@media screen and (min-width: 610px) and (max-width: 1200px) {
  #main__title {
    font-size: 60px;
  }

  #main {
    margin: 415px 0 0 34px;
  }

  #main__description {
    font-size: 16px;
    width: 427px;
    margin: 34px 0 0 0;
  }

  #main__button {
    width: 191.9px;
    height: 55px;
    font-size: 16px;
  }

  #main__deco {
    position: absolute;
    width: 1081.36px;
    height: 1088.68px;
    left: 120px;
    top: -430.48px;
    z-index: -999;
    transform: rotate(8.41deg);
  }


  #main__planet {
    width: 507.89px;
    height: 504.51px;
    left: 328px;
    top: 87px;
  }
}