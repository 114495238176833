#contact {
  margin-top: 145px;
  height: 1130px;
  width: 100vw;
  background-image: url(../assets/Contact.webp);
  background-size: cover;
}

#contact__title {
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 0 20px;
  color: #FFFFFF;
  display: none;
}

#contact__container {
  display: flex;
  flex-direction: column;
  margin-left: 183px;

  h2 {
    font-weight: 700;
    font-size: 40px;
    color: #FFFFFF;
    margin: 0 0 40px 0;
  }
}

.contact__container__text {
  font-weight: 300;
  font-size: 24px;
  width: 500px;
  line-height: 150%;
  margin: 0;
  color: #FFFFFF;

  &:last-child {
    font-size: 20px;

    b {
      font-size: 24px;
    }
  }
}

#contact__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 393px;
}

#contact__map {
  width: 952px;
  height: 578px;
  border-radius: 0 60px 60px 0;
  border: none;
}

#contact__map-block {
  position: absolute;
  width: 952px;
  height: 578px;
  background: #2196F3;
  border-radius: 0 60px 60px 0;
  top: 360px;
  left: -150px;
  z-index: -2;
}

footer {
  margin-top: 101px;

  p {
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
  }
}

@media screen and (min-width: 2000px) {
  #contact__wrapper {
    width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  #contact__map {
    border-radius: 60px;
  }

  #contact__map-block {
    width: 802px;
    left: 0;
    border-radius: 60px;
  }
}

@media screen and (max-width: 1500px) {
  #contact {
    margin-top: 92px;
    height: 696px;
    width: 100vw;
    background-image: url(../assets/Contact.webp);
    background-size: cover;
  }

  #contact__container {
    margin-left: 92px;

    h2 {
      font-size: 20px;
      margin: 0 0 20px 0;
    }
  }

  .contact__container__text {
    font-size: 16px;
    width: 366px;

    &:last-child {
      font-size: 16px;

      b {
        font-size: 16px;
      }
    }
  }

  #contact__wrapper {
    padding-top: 252px;
  }

  #contact__map {
    width: 560px;
    height: 325px;
    border-radius: 0 40px 40px 0;
  }

  #contact__map-block {
    width: 513px;
    height: 349px;
    border-radius: 0 40px 40px 0;
    top: 220px;
    left: -20px;
    z-index: -2;
  }

  footer {
    margin-top: 81px;

    p {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 701px) and (max-width: 1200px) {
  #contact {
    margin-top: 79px;
    height: 606px;
  }

  #contact__container {
    margin-left: 44px;

    h2 {
      font-size: 18px;
    }
  }

  .contact__container__text {
    font-size: 14px;
    width: 316px;

    &:last-child {
      font-size: 14px;

      b {
        font-size: 14px;
      }
    }
  }

  #contact__wrapper {
    padding-top: 230px;
  }

  #contact__map {
    width: 403px;
    height: 228.47px;
    border-radius: 0 20px 20px 0;
  }

  #contact__map-block {
    width: 403px;
    height: 245.05px;
    border-radius: 0 20px 20px 0;
    top: 210px;
    left: -40px;
    z-index: -2;
  }

}

@media screen and (max-width: 700px) {
  #contact {
    margin-top: 120px;
    height: 710px;
    position: relative;
    z-index: 1;
  }

  #contact__title {
    display: inline;

    &:after {
      content: '';
      position: absolute;
      width: 250px;
      height: 15px;
      border-radius: 18.5px 0 0 18.5px;
      background-color: #2196F3;
      top: 5px;
      right: 0;
      z-index: 1;
    }
  }

  #contact__wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 54px;
  }

  #contact__map {
    z-index: 3;
    width: 356px;
    height: 262px;
    border-radius: 0 30px 30px 0;
  }

  #contact__map-block {
    width: 308px;
    height: 262px;
    border-radius: 0 30px 30px 0;
    top: 35px;
    left: 0;
    z-index: -3;
  }

  #contact__container {
    margin-left: 20px;
    margin-top: 60px;

    h2 {
      font-size: 20px;
      margin: 0 0 40px 0;
    }
  }

  .contact__container__text {
    font-size: 14px;
    width: 335px;

    &:last-child {
      font-size: 14px;

      b {
        font-size: 14px;
      }
    }
  }

  footer {
    margin-top: 60px;

    p {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 360px) {
  #contact__title {
    font-size: 16px;

    &:after {
      content: '';
      position: absolute;
      width: 210px;
      height: 15px;
      border-radius: 18.5px 0 0 18.5px;
      background-color: #2196F3;
      top: 2px;
      right: 0;
      z-index: 1;
    }
  }

  #contact__map {
    z-index: 3;
    width: 310px;
    height: 242px;
    border-radius: 0 30px 30px 0;
  }

  #contact__map-block {
    width: 270px;
    height: 242px;
    border-radius: 0 30px 30px 0;
    top: 35px;
    left: 0;
    z-index: -3;
  }

  .contact__container__text {
    font-size: 13px;
    width: 310px;

    &:last-child {
      font-size: 13px;

      b {
        font-size: 13px;
      }
    }
  }
}