#portfolio {
  padding-right: 20px;
  width: 800px;
  height: 970px;
  position: fixed;
  top: 0;
  background: url(../assets/Portfolio.webp);
  background-size: cover;
  border-radius: 40px;
  left: 50%;
  margin-left: -400px;
  z-index: 3;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

#portfolio-background {
  position: fixed;
  cursor: pointer;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

::-webkit-scrollbar-track {
  background: transparent;
}


::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#portfolio__header {
  display: flex;
  margin-top: 49px;
  align-items: center;
  margin-left: 58px;

  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 124px;
    height: 124px;
    border: 3px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    top: -10px;
    left: 5px;
    z-index: -1;
  }

  &:after {
    content: "";
    position: absolute;
    width: 685px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    top: 150px;
    left: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
  }
}

#portfolio__header__image {
  width: 124px;
  height: 124px;
  border-radius: 22px;
}

#portfolio__header__name {
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
  margin: 0;
}

#portfolio__header__post {
  font-weight: 700;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin: 16px 0 0 0;
}

#portfolio__post {
  margin: 56px 0 0 58px;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 685px;
}

#portfolio__postData {
  margin-left: 168px;
  padding: 0;
}

.portfolio__postData__item {
  font-weight: 500;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  list-style: none;
}

#portfolio__experience {
  margin: 40px 0 0 58px;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 685px;
}

.portfolio__experience__item {
  margin-left: 168px;
  list-style: none;
  font-weight: 500;
  font-size: 18px;
  margin-top: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.portfolio__experience__item__container {
  margin-left: 225px;
}

.portfolio__experience__item__container__text {
  font-weight: 500;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  list-style: none;
  width: 474px;
}

#portfolio__education {
  margin: 40px 0 0 58px;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-bottom: 50px;
}

#portfolio__header__svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  right: 57px;
  cursor: pointer;
}

@media screen and (min-width: 2000px) {
  #portfolio {
    height: 100vh;
  }
}

@media screen and (max-width: 1500px) {
  #portfolio {
    height: 100vh;
  }
}

@media screen and (min-width: 610px) and (max-width: 1200px) {
  .portfolio__experience__item {
    width: 604px;
  }
}

@media screen and (max-width: 600px) {
  #portfolio {
    width: 100vw;
    height: 100vh;
    position: fixed;
    border-radius: 0;
    margin-left: -50vw;
    z-index: 3;
    overflow: auto;
    left: 50%;
  }

  #portfolio__header {
    margin-left: 23px;

    &:after {
      width: 334px;
    }

    div {
      margin-left: 30px;
    }
  }

  #portfolio__header__image {
    border-radius: 12px;
  }

  #portfolio__post {
    margin: 51px 0 0 18px;
    font-size: 18px;
    width: 337px;
  }

  #portfolio__postData {
    margin-left: 58px;
    padding: 0;
  }

  #portfolio__experience {
    margin: 40px 0 0 18px;
    font-size: 18px;
    width: auto;
  }

  .portfolio__experience__item {
    margin-left: 58px;
  }

  .portfolio__experience__item__container {
    margin-left: 58px;
    padding: 0;
  }

  .portfolio__experience__item__container__text {
    width: 297px;
  }

  #portfolio__header__svg {
    top: -20px;
    right: 20px;
    cursor: pointer;
  }

  #portfolio__education {
    margin: 40px 0 0 18px;
    font-weight: 400;
    font-size: 18px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 320px) {
  #portfolio__header {
    margin-left: 15px;

    &:after {
      width: 290px;
    }

    div {
      margin-left: 25px;
    }
  }

  #portfolio__post {
    font-size: 16px;
    width: 300px;
  }

  #portfolio__postData {
    margin-left: 40px;
    padding: 0;
  }

  #portfolio__experience {
    margin: 40px 0 0 18px;
    font-size: 16px;
    width: auto;
  }

  .portfolio__experience__item {
    margin-left: 40px;
  }

  .portfolio__experience__item__container {
    margin-left: 58px;
    padding: 0;
  }

  .portfolio__experience__item__container__text {
    width: 250px;
  }

  #portfolio__header__svg {
    top: -10px;
    right: 20px;
    cursor: pointer;
  }

  #portfolio__education {
    font-size: 16px;
    padding-bottom: 30px;
  }

  .portfolio__postData__item {
    font-size: 15px;
  }

  .portfolio__experience__item {
    font-size: 15px;
  }

  .portfolio__experience__item__container__text {
    font-size: 15px;
  }
}