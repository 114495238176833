header {
  display: flex;
  align-items: center;
  padding-top: 80px;
  padding-left: 126px;
}

#header__logo {
  width: 55px;
  height: 55px;
}

#header__name {
  font-weight: 500;
  font-size: 25px;
  //margin: 0 0 0 20px;
  margin: 0;
  color: #fff;
}

.header__menu {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.header__menu__link {
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  list-style: none;
  margin-right: 60px;
  transition: 0.2s ease-in-out;

  &:after {
    transition: 0.3s ease-in-out;
    content: '';
    border-radius: 1px;
    width: 50%;
    height: 3px;
    background: #B2EBF2;
    position: absolute;
    bottom: -8px;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }


  &:hover {
    color: #B2EBF2;
    position: relative;

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

#header__burger {
  width: 28px;
  height: 20px;
  margin-left: auto;
  margin-right: 40px;
  display: none;
}

#header__close {
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 20px;
  display: none;
}

@media screen and (min-width: 2000px) {
  header {
    width: 1920px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
  }
}

@media screen and (min-width: 1210px) and (max-width: 1500px) {
  header {
    padding-top: 80px;
  }

  #header__name {
    font-size: 18px;
  }

  .header__menu {
    margin-left: 40px;
  }

  .header__menu__link {
    font-size: 16px;
    margin-right: 40px;
  }
}


@media screen and (max-width: 1200px) {
  #header__close {
    margin-right: 40px;
  }
  #header__logo {
    width: 35px;
    height: 35px;
  }
  header {
    padding-top: 30px;
    padding-left: 20px;
    top: 0;
    left: 0;
    width: 100%;
  }

  #header-active {
    &:after {
      position: absolute;
      content: '';
      width: 90%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      top: 99px;
      left: 20px;
      right: 20px;
      margin: 0;
    }
  }

  #header__burger {
    display: inline;
  }

  .header__menu {
    display: none;
  }

  #header__close {
    display: inline;
  }

  .header__menu__link {
    font-size: 18px;
    margin-bottom: 51px;
    margin-left: 20px;
  }

  #header__menu-active {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: fixed;
    top: 70px;
    left: 0;
    padding: 78px 0 0 0;
    margin: 0 0 0 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(23, 18, 23, 0) 0%, #171217 44.84%), url(../assets/Stars.webp);
  }
}
