body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #171217;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

html {
  overflow-x: hidden;
}

#arrow {
  display: flex;
  z-index: 3;
  position: fixed;
  bottom: 30px;
  font-weight: 400;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  width: 230px;
  left: 50%;
  margin-left: -165px;
  transition: 1s;

  path {
    transition: 1s;
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

  }

  svg {
    margin-left: 16px;
  }
}

@media screen and (max-width: 1500px) {
  #arrow {
    font-size: 18px;
    width: 205.08px;
    margin-left: -103px;

    svg {
      width: 17.08px;
      height: 16px;
    }
  }
}

@media screen and (max-width: 600px) {
  #arrow {
    display: none;
  }
}